import { FaLinkedin, FaFacebookF, FaInstagram } from 'react-icons/fa6'
//import logoAlt from './cfaLogoAlt.png'

const Footer = () => {
    return (
        <div style={{ backgroundColor: '#2E2E2E', color: 'white', borderTop: '2px solid white', }}>
            <br />
            <div className='container ' style={{ padding: '25px' }}>
                <div className="col-sm-12">
                    If you are vision-impaired or have some other impairment and you wish to discuss potential accommodations related to using this website, please email us at <a href="mailto:accessibility@insureherenow.com"> accessibility@insureherenow.com</a>
                    .
                    <br />
                    <br />
                    © 2024 InsureHereNow. All rights reserved
                </div>

            </div>
        </div>
    )
}

export default Footer;
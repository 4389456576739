import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi';
import bodyPhoto from '../computer.jpg';

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div>
            <div className='container' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <br /><br />
                <div style={{ fontSize: '40px', fontWeight: 'bold', textAlign: 'center' }}>

                    Frequently Asked Questions (FAQs) About Burial Insurance
                </div>
                <br />
                <div style={{ maxWidth: '800px', textAlign: 'center', display: 'flex', justifyContent: 'center', }}>

                    Burial insurance is a small life insurance product that is affordable and easy to qualify for. If you don’t have sufficient savings to pay for your final expenses, burial insurance will help prevent your loved ones from having to pay for your funeral out of their pocket.
                </div>

            </div>
            <br />
            <hr />
            <br />
            <div style={{ display: 'flex', justifyContent: 'center', color: 'black' }} >

                <div className="row container justify-content-center" style={{ padding: '25px 0' }}>

                    <div
                        className="col-sm-12 col-md-6"
                        style={{ fontSize: '15px', marginBottom: '25px' }}

                    >


                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Why do I need to buy burial insurance?
                        </div>
                        <br />
                        Burial insurance is a small life insurance product that is affordable and easy to qualify for. If you don’t have sufficient savings to pay for your final expenses, burial insurance will help prevent your loved ones from having to pay for your funeral out of their pocket.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Do my beneficiaries have to use my burial insurance to pay for my funeral expenses?
                        </div>
                        <br />
                        Your beneficiaries get the funds after your death and are in charge of disbursing funds to pay your final expenses, bills or fees. It’s important to talk with your beneficiaries about the intent of your final expense insurance, so they understand your wishes.


                        <br /><br />


                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Do funeral insurance premiums change over the years?

                        </div>
                        <br />
                        Your insurance premium is based on the coverage you choose, your health, and your age. The premiums will remain the same as you get older, even if your health declines or if you are diagnosed with a severe illness.
                        <br />
                        Certain policies have restrictions and may not pay out 100% if you die before a certain period of time has passed. Make sure you understand the funeral policy you are considering and any limitations it may have.


                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Is there any difference between burial insurance, funeral insurance, final expense insurance and pre-need insurance?


                        </div>
                        <br />
                        The terms burial insurance, funeral insurance and final expense insurance are all names for small life insurance policies designed to cover the cost of your final expenses. Pre-need insurance is a contract with a specific funeral service provider that covers the cost of the funeral service and products you’ve chosen at a predetermined price.


                        <br /><br />

                    </div>

                    <div className="col-sm-12 col-md-6">

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What’s the difference between burial insurance and life insurance?
                        </div>
                        <br />
                        The purpose of life insurance is to provide financial protection to surviving dependents after the death of the insured person. It can be used by the beneficiary to replace an income, cover children’s expenses, or pay off debts, estate taxes or final expenses.
                        <br /><br />
                        The intent of funeral insurance is to cover funeral and other end-of-life costs only. The beneficiary of a funeral insurance policy is paid the benefit upon the insured person’s death. They can then determine how to use the proceeds from the funeral insurance.
                        <br /><br />
                        While it’s still a life insurance product, burial insurance usually costs much less, is easier to obtain and has a lower coverage limit than traditional life insurance policies.


                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can I get burial insurance if I smoke, have a pre-existing condition or a serious illness?
                        </div>
                        <br />
                        Yes. While you won’t have to have a medical exam, you will need to answer a health questionnaire that could affect your premium and coverage. Funeral insurance is a popular option for people who can’t qualify for traditional life insurance because of their health.



                        <br /><br />


                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can I name someone other than a relative as a beneficiary of my policy?
                        </div>
                        <br />
                        You can choose anyone to be the beneficiary of your final expense life insurance policy. While many people select family members to be the primary and secondary beneficiaries, the most important thing is to choose someone responsible and dependable. The beneficiaries you name are usually the people in charge of paying your final expenses.


                        <br /><br />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Body;

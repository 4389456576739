import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import HomePage from './HomePage/HomePage';
import About from './About/About';
import FAQ from './FAQ/FAQ';

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the home page */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        {/* Route for the home page 
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
*/}
      </Routes>

    </Router>
  );
}

export default App








import logo from './logo.png';
import { CgEnter, CgMenuRight } from 'react-icons/cg';
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";

const Nav = () => {
    function scrollToElement(id) {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    return (
        <>

            <nav class="navbar sticky-top navbar-expand-lg p-0" style={{ borderBottom: '2px solid white', backgroundColor: '#3A4B65' }}>
                <div class="container " style={{ backgroundColor: '#3A4B65', }}>
                    <a class="navbar-brand" href="/" style={{ padding: '20px 0' }}>
                        <img src={logo} alt="" style={{ height: '75px', maxWidth: '60vw', objectFit: 'contain', }} />
                    </a>
                    <a class='navbar-toggler' data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="" style={{ color: '#41628B', fontSize: '30px' }}><CgMenuRight /></span>
                    </a>

                    <div class="collapse navbar-collapse" id="navbarToggler" >
                        <div></div>
                        <ul class="navbar-nav ms-auto mt-1 mb-lg-0" style={{ fontWeight: 'bold', }} >

                            <a href='/' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                Home
                            </a>
                            <a href='/about' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                About
                            </a>
                            <a href='/faq' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                FAQ
                            </a>

                            <a style={{ display: 'flex', justifyContent: 'center', padding: '20px 0', marginTop: '-5px' }}>

                                <button className='shadow' style={{ border: 'white 3px solid', backgroundColor: '#866F1D', color: 'white', padding: '5px 20px', borderRadius: '25px', width: '150px', height: '50px' }}>Get A Quote</button>
                            </a>
                            <div className='d-md-none d-lg-block' style={{ padding: '20px 20px', color: 'white' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', fontSize: '12.5', textAlign: 'center' }}>
                                    Call to speak with a Licensed Sales Agent
                                </div>
                                <a href="tel:8004561920">
                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: 'bold' }}>
                                        800-456-1920
                                    </div>
                                </a>
                            </div>
                        </ul>


                    </div>

                </div >
            </nav >
        </>
    );
}

export default Nav;
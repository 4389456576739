
import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi'
import headerPhoto from '../hands.jpg'

const Header = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);



    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <div className="row container align-items-center justify-content-center" style={{ padding: '25px 0' }}>


                <motion.div
                    className="col-sm-12 col-md-7"
                    style={{ color: 'black', fontSize: '15px', marginBottom: '25px' }}
                    initial="hidden"
                    animate={controls}
                    variants={{
                        hidden: { opacity: 0, x: -100 },
                        visible: { opacity: 1, x: 0 }
                    }}
                    transition={{ duration: 1.5 }}
                    ref={ref}
                >

                    <div style={{ fontSize: '25px', fontWeight: 'bold' }}>

                        What is burial insurance?
                    </div>
                    <br />
                    You buy insurance to protect yourself and manage risk. Car, homeowners, health, and life insurance are all products that help safeguard your finances.
                    <br />
                    <br />
                    Burial insurance is a basic type of life insurance. It’s designed to safeguard your finances and those of your loved ones at your passing by covering costs associated with your funeral or cremation. Also known as funeral insurance or final expense insurance, these policies are more affordable and easier to qualify for than other life insurance products.
                    <br /><br />
                    As expenses and funeral costs continue to rise, so does the need for funeral insurance. That’s why it’s important to understand what a burial policy covers, who needs it, and how to buy a final expense insurance product that’s right for you.


                </motion.div>
                <div className="col-sm-12 col-md-5 " >
                    <div style={{ color: 'black', fontSize: '30px', }}>
                        <img
                            src={headerPhoto}
                            className="img-fluid shadow"
                            alt=""
                            style={{ objectFit: 'cover', border: '2px white solid' }}

                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginBottom: '1rem', display: 'flex', fontSize: '15px', padding: '20px' }}>
                                <input
                                    type="text"
                                    placeholder='Enter Zip'
                                    style={{
                                        height: '50px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px 0 0 4px',
                                        width: '200px',
                                        outline: 'none'
                                    }}
                                />
                                <button
                                    type="button"
                                    style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: '0 4px 4px 0',
                                        width: '120px',
                                        background: '#3A4B65',
                                        color: 'white',
                                        borderLeft: 'none'
                                    }}
                                >
                                    Get A Quote
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;
import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Cards = () => {

    const loanTypes = [
        {
            "title": "What is burial insurance?",
            //"icon": lineOfCredit,
            "description": "Burial insurance is a final expense life insurance policy your family can use to pay funeral expenses or other bills when you pass away.",


        },
        {
            "title": "How does burial insurance work?",
            //"icon": lineOfCredit,
            "description": "Similar to life insurance, you pay regular premiums for a set amount of coverage, which will be paid to your beneficiary when you die. The funeral insurance payout can be used to pay funeral costs, medical fees, or other unpaid bills.",


        },
        {
            "title": "How much burial insurance do I need?",
            //"icon": lineOfCredit,
            "description": "Plans are available with coverage from $1,000 up to $40,000. Once you secure your rate, it never increases. With average funeral costs nearing (and often exceeding) $10,000 – it pays to plan.",


        },

    ];

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.15
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: {
            opacity: 1, y: 0, transition: {
                duration: 1.25
            }
        }
    };

    return (
        <div className="container" id="fundingSolutions" style={{ padding: '50px 0' }}>
            <motion.div
                ref={ref}
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '25px' }}
                variants={containerVariants}
                initial="hidden"
                animate={controls}
            >
                {
                    loanTypes.map((loan, index) => (
                        <motion.div
                            key={index}
                            style={{ width: '400px', backgroundColor: 'white', borderRadius: '10px', padding: '25px' }}
                            variants={cardVariants}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div style={{ color: '#3A4B65', fontWeight: 'bold', fontSize: '35px' }}>
                                    {loan.title}
                                </div>
                                <div>
                                    <img src={loan.icon} style={{ height: '30px' }} alt="" />
                                </div>
                            </div>
                            <div>
                                {loan.description}
                            </div>

                            <a href="/about#body" style={{ color: '#866F1D', fontSize: '20px' }}>Learn More</a>


                        </motion.div>
                    ))
                }
            </motion.div>
        </div>
    );
}

export default Cards;

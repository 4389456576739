import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi';
import bodyPhoto from '../computer.jpg';

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#3A4B65', color: 'white' }} >
            <div className="row container align-items-center justify-content-center" style={{ padding: '25px 0' }}>

                <div
                    className="col-sm-12 col-md-5"
                    style={{ fontSize: '15px', marginBottom: '25px' }}

                >
                    <img
                        src={bodyPhoto}
                        className="img-fluid shadow"
                        alt=""
                        style={{ objectFit: 'cover', border: '2px white solid' }}
                    />
                    <br /><br />

                    <div id='body' style={{ fontSize: '25px', fontWeight: 'bold' }}>
                        Who needs final expense insurance?
                    </div>
                    <br />
                    After researching the type of funeral you want, and what it will cost, determine if you have adequate savings to cover your final expenses. If you already have life insurance, is there enough to take care of your dependents and any other debts you have?
                    <br /><br />
                    Make sure you take into account the increasing cost of funerals and inflation. If you have doubts your savings will pay for your final expenses, consider buying a burial insurance policy to supplement your existing plan.
                    <br /><br />
                    If you haven’t been able to save money for your funeral and if you don’t have life insurance, it makes sense to consider buying burial coverage. Funerals are expensive and without a plan to pay for yours when upon your death – you’ll be passing on your final expenses to your loved ones.
                </div>

                <div className="col-sm-12 col-md-7">
                    <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                        What You Need to Know About Funeral Insurance
                    </div>
                    <br />
                    Thinking about and planning for your death is difficult, but consider how it will protect your loved ones- emotionally and financially. Making funeral plans and deciding how to fund them up front prevents your family from going into debt when you eventually pass away.
                    <br /><br />
                    Burial insurance is a final expense life insurance policy covering funeral services and related costs at the time of death. Final expenses include the funeral home’s service and preparation fees, the cost of staff and facilities for viewings, and costs for burial or cremation. Burial insurance can also be used to pay for cemetery plots and headstones or extra expenses such as flowers and wreaths.
                    <br /><br />
                    With average funeral costs* between $7,000 and $9,000, your beneficiary can use burial insurance to pay for your funeral. But they might also use money from your funeral insurance to pay for other final expenses, including medical bills or other debts.
                    <br />
                    <br />
                    <button style={{ border: 'white 3px solid', backgroundColor: '#866F1D', color: 'white', padding: '10px 20px', borderRadius: '25px', width: '150px' }}>Get A Quote</button>


                    <br /><br />
                    <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                        How to Buy Burial Insurance
                    </div>
                    <br />
                    After estimating your final expenses and looking at your savings, you’ll have a good idea of how much burial insurance to purchase. Typical funeral policies range from $1,000 to $40,000. The average monthly premium is about $50 for a $10,000 burial policy.
                    <br /><br />
                    You can then call 1-855-634-7399 to speak with a licensed sales agent or get a free final expense life insurance quote online.
                    <br /><br />
                    Remember, burial insurance prices are lower than most life insurance policies. You also won’t need a medical exam to be approved to buy a final expense life insurance policy.
                </div>
            </div>
        </div>
    );
}

export default Body;
